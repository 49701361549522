<template>
    <div class="login">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-12">
                    <h1 class="custom-h1">
                        <span class="custom-color-primary">HOL</span
                        ><span class="custom-color-secondary">EFFECT</span>
                    </h1>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-12">
                    <h2 class="custom-color-primary custom-h2">
                        Créer un compte
                    </h2>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-6"></div>
                <div class="col-lg-2 text-right">
                    <p class="custom-color-secondary">*Champs requis</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4"></div>
                <div class="col-lg-4">
                    <b-form-input
                        placeholder="Nom d'utilisateur*"
                        class="custom-form-input"
                        v-model="userName"
                    ></b-form-input>
                </div>
                <div class="col-lg-4"></div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-4"></div>
                <div class="col-lg-4">
                    <b-form-input
                        placeholder="Mot de passe*"
                        class="custom-form-input"
                        :type="type"
                        v-model="password"
                    ></b-form-input>
                </div>
                <div class="col-lg-4"></div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-4"></div>
                <div class="col-lg-4">
                    <b-form-input
                        placeholder="Confirmer le mot de passe*"
                        class="custom-form-input"
                        :type="type"
                        v-model="confirmPassword"
                    ></b-form-input>
                </div>
                <div class="col-lg-4"></div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-4"></div>
                <div class="col-lg-4">
                    <b-form-input
                        placeholder="Numéro de téléphone*"
                        class="custom-form-input"
                        v-model="phonenumber"
                    ></b-form-input>
                </div>
                <div class="col-lg-4"></div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-4"></div>
                <div class="col-lg-4">
                    <b-form-input
                        placeholder="Email*"
                        class="custom-form-input"
                        v-model="email"
                    ></b-form-input>
                </div>
                <div class="col-lg-4"></div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-4"></div>
                <div class="col-lg-4">
                    <b-form-input
                        placeholder="Adresse*"
                        class="custom-form-input"
                        v-model="address"
                    ></b-form-input>
                </div>
                <div class="col-lg-4"></div>
            </div>
            <div class="row">
                <div class="col-lg-5"></div>
                <div class="col-lg-2">
                    <hr class="custom-hr" />
                </div>
                <div class="col-lg-5"></div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-4"></div>
                <div class="col-lg-4">
                    <b-button
                        class="custom-btn-color"
                        v-on:click="createAccount"
                        >Créer le compte</b-button
                    >
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-4"></div>
                <div class="col-lg-4">
                    <span v-on:click="showPassword" class="pointer">
                        Mot de passe <b-icon-eye></b-icon-eye>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data: function () {
        return {
            userName: "",
            password: "",
            confirmPassword: "",
            phonenumber: "",
            email: "",
            address: "",
            type: "password",
        };
    },
    methods: {
        createAccount: async function () {
            //Todo Code appel axios création du compte
            await this.$store.dispatch("user/register", {
                userName: this.userName,
                password: this.password,
                phonenumber: this.phonenumber,
                email: this.email,
                address: this.address,
            });

            this.$router.push({ name: "ManageClient" });
        },
        showPassword: function () {
            if (this.type == "password") {
                this.type = "text";
            } else {
                this.type = "password";
            }
        },
    },
};
</script>
<style scoped>
.custom-h1 {
    font-size: 80px;
}
.custom-h2 {
    font-size: 50px;
}
.custom-hr {
    border: 3px solid #11a9b9;
}
.custom-btn-color {
    background-color: #0e476b;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}
.login {
    padding: 60px;
}
</style>
